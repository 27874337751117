<template>
  <div id="nav" :class="$style.header">
    <div :class="$style.title">
      <ul :class="$style.breadcrumb">
        <li>
          <router-link to="/">Меню</router-link>
        </li>
        <li v-for="(link, index) in curBreadcrumb" :key="index">
          <span v-if="index === curBreadcrumb.length - 1">
            {{ link.text }}
          </span>
          <router-link v-else :to="getRouterLink(link.to)">
            {{ link.text }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'

export default {
  name: 'SellersHeader',
  ADDSELLERS_ADMIN_ROUTES,
  data() {
    return {
      breadcrumb: {
        SignIn: [{ text: 'Вход в AddSellers', to: '/sellers/sign-in' }],
        coordinatorChats: [{ text: 'Чаты координатора', to: '/chats' }],
        coordinatorChat: [
          { text: 'Чаты координатора', to: '/chats' },
          { text: 'Чат с пользователем', to: '/chats/:id' },
        ],
        SellersSettings: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Настройки', to: '/sellers/settings' },
        ],

        sellersClientRequesite: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Реквизиты клиентов', to: '/sellers/client-requesites' },
        ],
        editClientRequesite: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Реквизиты клиентов', to: '/sellers/client-requesites' },
          { text: 'Модерация клиента', to: '' },
        ],
        orders: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Заказы', to: ADDSELLERS_ADMIN_ROUTES.ORDERS_INDEX },
        ],
        sellersOrders: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Заказы', to: ADDSELLERS_ADMIN_ROUTES.ORDERS_INDEX },
          {
            text: 'Заказы AddSellers',
            to: ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.LIST,
          },
        ],
        sellersOrder: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Заказы',
            to: ADDSELLERS_ADMIN_ROUTES.ORDERS_INDEX,
          },
          {
            text: 'Заказы AddSellers',
            to: ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.LIST,
          },
          {
            text: 'Заказ',
            to: ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.VIEW,
          },
        ],
        editSellersOrder: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Заказы', to: ADDSELLERS_ADMIN_ROUTES.ORDERS_INDEX },
          {
            text: 'Заказы AddSellers',
            to: ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.LIST,
          },
          {
            text: 'Изменить заказ',
            to: ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.UPDATE,
          },
        ],
        offsellersOrders: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Заказы', to: ADDSELLERS_ADMIN_ROUTES.ORDERS_INDEX },
          {
            text: 'Заказы OffSellers',
            to: ADDSELLERS_ADMIN_ROUTES.ORDERS.OFFSELLERS.LIST,
          },
        ],
        offsellersOrder: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Заказы',
            to: ADDSELLERS_ADMIN_ROUTES.ORDERS_INDEX,
          },
          {
            text: 'Заказы OffSellers',
            to: ADDSELLERS_ADMIN_ROUTES.ORDERS.OFFSELLERS.LIST,
          },
          {
            text: 'Заказ',
            to: ADDSELLERS_ADMIN_ROUTES.ORDERS.OFFSELLERS.VIEW,
          },
        ],
        sellersDealers: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
        ],
        sellersDealersBonusCountList: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          {
            text: 'Бонусные баллы Дилеров',
            to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST_WITH_BONUS_COUNT,
          },
        ],
        sellersDealersNotes: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          {
            text: 'Заметки',
            to: ADDSELLERS_ADMIN_ROUTES.DEALERS.NOTES.LIST,
          },
        ],
        sellersDealersList: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
        ],
        sellersCreateDealers: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          { text: 'Создать дилера', to: '/sellers/dealers/create-account' },
        ],
        sellersEditDealers: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          { text: 'Изменить дилера', to: '/sellers/dealers/edit-account' },
        ],
        sellersDealersOrders: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          { text: 'Заказы', to: '/sellers/dealers/orders/:id' },
        ],
        sellersDealersOrdersCreate: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          { text: 'Заказы', to: '/sellers/dealers/:id/orders' },
          { text: 'Создать заказ', to: '/sellers/dealers/:id/orders/create' },
        ],
        sellersClientRequesites: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          {
            text: 'Реквизиты клиента',
            to: '/sellers/dealers/:id/client-requesites',
          },
        ],
        sellersClientRequesitesCreate: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          {
            text: 'Реквизиты клиента',
            to: '/sellers/dealers/:id/client-requesites',
          },
          {
            text: 'Создание реквизитов',
            to: '/sellers/dealers/:id/client-requesites/create',
          },
        ],
        sellersDealersDeliveryAddresses: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          {
            text: 'Адреса доставки',
            to: '/sellers/dealers/:id/delivery-addresses',
          },
        ],
        sellersDealersDeliveryAddressesCreate: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          {
            text: 'Адреса доставки',
            to: '/sellers/dealers/:id/delivery-addresses',
          },
          {
            text: 'Создание адреса доставки',
            to: '/sellers/dealers/:id/delivery-addresses/create',
          },
        ],
        sellersDealersDeliveryAddressesUpdate: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          {
            text: 'Адреса доставки',
            to: '/sellers/dealers/:id/delivery-addresses',
          },
          {
            text: 'Редактирование адреса доставки',
            to: '/sellers/dealers/:id/delivery-addresses/:addressId',
          },
        ],
        sellersDealersRecipients: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          {
            text: 'Получатели',
            to: '/sellers/dealers/:id/recipients',
          },
        ],
        sellersDealersRecipientCreate: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          {
            text: 'Получатели',
            to: '/sellers/dealers/:id/recipients',
          },
          {
            text: 'Создание получателя',
            to: '/sellers/dealers/:id/recipients/create',
          },
        ],
        sellersDealersRecipientUpdate: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          {
            text: 'Получатели',
            to: '/sellers/dealers/:id/recipients',
          },
          {
            text: 'Редактирование получателя',
            to: '/sellers/dealers/:id/recipients/:recipientId',
          },
        ],
        sellersDealersBonusTransactions: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX },
          { text: 'Все Дилеры', to: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST },
          {
            text: 'Бонусные транзакции',
            to: '/sellers/dealers/:id/bonus-transactions',
          },
        ],
        sellersDownloads: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Загрузка', to: '/sellers/downloads' },
        ],
        sellersDownloadsFiles: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Загрузка', to: '/sellers/downloads' },
          { text: 'Раздел', to: '/sellers/downloads/:name' },
        ],
        sellersOffers: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Акции', to: '/sellers/offers' },
        ],
        sellersCreateOffer: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Акции', to: '/sellers/offers' },
          { text: 'Создать акцию', to: '/sellers/offers/create-offer' },
        ],
        sellersEditOffer: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Акции', to: '/sellers/offers' },
          { text: 'Редактировать акцию', to: '/sellers/offers/edit-offer/:id' },
        ],
        sellersMarketingNotification: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Маркетинговые уведомления',
            to: '/sellers/marketing-notifications',
          },
        ],
        sellersCreateMarketingNotification: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Маркетинговые уведомления',
            to: '/sellers/marketing-notifications',
          },
          { text: 'Создание', to: '/sellers/marketing-notifications/create' },
        ],
        sellersEditMarketingNotification: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Маркетинговые уведомления',
            to: '/sellers/marketing-notifications',
          },
          {
            text: 'Редактирование',
            to: '/sellers/marketing-notifications/edit/:id',
          },
        ],
        sellersSruppliersCatalogs: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Поставщики', to: '/sellers/suppliers' },
        ],
        sellersEditSuppliersCatalog: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Поставщики', to: '/sellers/suppliers' },
          { text: 'Редактировать каталог', to: '/sellers/suppliers/:id/edit' },
        ],
        sellersProducts: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Главный каталог', to: '/sellers/products' },
        ],
        sellersCatalogs: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Каталоги', to: '/sellers/catalogs' },
        ],
        sellersEditCatalog: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Каталоги', to: '/sellers/catalogs' },
          { text: 'Редактировать каталог', to: '/sellers/catalogs/:id/edit' },
        ],
        sellersChats: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Чаты', to: '/sellers/chats' },
        ],
        sellersChat: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Чаты', to: '/sellers/chats' },
          { text: 'Чат с пользователем', to: '/sellers/chats/:id' },
        ],
        sellersTemplates: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Шаблоны', to: '/sellers/templates' },
        ],
        sellersInstructionalVideos: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Видеоматериалы', to: '/sellers/instructional-videos' },
        ],
        sellersCoordinators: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Координаторы', to: '/sellers/coordinators' },
        ],
        sellersCreateCoordinators: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Координаторы', to: '/sellers/coordinators' },
          { text: 'Создать координтора', to: '/sellers/coordinators/create' },
        ],
        sellersEditCoordinators: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Координаторы', to: '/sellers/coordinators' },
          {
            text: 'Изменить координтора',
            to: '/sellers/coordinators/edit/:id',
          },
        ],
        sellersSubscriptions: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Подписки', to: '/sellers/subscriptions' },
        ],
        sellersDealerAccountSubscriptions: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Подписки', to: '/sellers/subscriptions' },
          {
            text: 'По диллеру',
            to: '/sellers/subscriptions/dealer-account/:id',
          },
        ],
        sellersProductSubscriptions: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Подписки', to: '/sellers/subscriptions' },
          {
            text: 'По продукту',
            to: '/sellers/subscriptions/product/:id',
          },
        ],
        sellersPages: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.LIST },
          {
            text: 'Страницы',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.LIST.INDEX,
          },
        ],
        sellersPagesMain: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.LIST },
          {
            text: 'Страницы',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.LIST.INDEX,
          },
          { text: 'Главная', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
        ],
        sellersPagesSupplier: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.LIST },
          {
            text: 'Страницы',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.LIST.INDEX,
          },
          {
            text: 'Поставщики',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.SUPPLIERS.INDEX,
          },
        ],
        sellersPagesRetailer: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.LIST },
          {
            text: 'Страницы',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.LIST.INDEX,
          },
          {
            text: 'Ритейлеры',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.RETAILERS.INDEX,
          },
        ],
        sellersPagesSettings: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.LIST },
          {
            text: 'Страницы',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.LIST.INDEX,
          },
          {
            text: 'Настройки',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.SITE_SETTINGS.INDEX,
          },
        ],
        sellersPagesConfidentialPolicy: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.LIST },
          {
            text: 'Страницы',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.LIST.INDEX,
          },
          {
            text: 'Политика конфеденциальности',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.CONFIDENTIAL_POLICY.IDNEX,
          },
        ],
        sellersPagesBonusProgram: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.LIST },
          {
            text: 'Страницы',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.LIST.INDEX,
          },
          {
            text: 'Бонусная программа',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.BONUS_PROGRAM.INDEX,
          },
        ],
        sellersPagesPublicOffer: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.LIST },
          {
            text: 'Страницы',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.LIST.INDEX,
          },
          {
            text: 'Публичная оферта',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.PUBLIC_OFFER.INDEX,
          },
        ],
        sellersFeedbacks: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Заявки',
            to: '/sellers/feedbacks',
          },
        ],
        sellersPricesPolicy: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Список ценовых политик',
            to: '/sellers/prices-policy',
          },
        ],
        sellersCreatePricesPolicy: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Список ценовых политик',
            to: '/sellers/prices-policy',
          },
          {
            text: 'Создание ценовой политики',
            to: '/sellers/prices-policy/create',
          },
        ],
        sellersBrands: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Бренды',
            to: ADDSELLERS_ADMIN_ROUTES.BRANDS.LIST,
          },
        ],
        sellersBrandsCreate: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Бренды',
            to: ADDSELLERS_ADMIN_ROUTES.BRANDS.LIST,
          },
          {
            text: 'Создать',
            to: ADDSELLERS_ADMIN_ROUTES.BRANDS.CREATE,
          },
        ],
        sellersBrandsEdit: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Бренды',
            to: ADDSELLERS_ADMIN_ROUTES.BRANDS.LIST,
          },
          {
            text: 'Редактировать',
            to: ADDSELLERS_ADMIN_ROUTES.BRANDS.UPDATE,
          },
        ],
        sellersBonusProgram: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Список бонусных программ',
            to: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.LIST,
          },
        ],
        sellersBonusProgramCreate: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Список бонусных программ',
            to: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.LIST,
          },
          {
            text: 'Создать',
            to: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.CREATE,
          },
        ],
        sellersBonusProgramEdit: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Список бонусных программ',
            to: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.LIST,
          },
          {
            text: 'Редактировать',
            to: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.EDIT,
          },
        ],
        sellersBonusProgramActive: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Список бонусных программ',
            to: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.LIST,
          },
          {
            text: 'Активная',
            to: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.ACTIVE.INDEX,
          },
        ],
        sellersBonusProgramArchive: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Список бонусных программ',
            to: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.LIST,
          },
          {
            text: 'Архивная',
            to: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.ARCHIVE.INDEX,
          },
        ],
        sellersBonusProgramExcludeDealers: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          {
            text: 'Список бонусных программ',
            to: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.LIST,
          },
          {
            text: 'Исключение дилеров',
            to: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.EXCLUDE_DEALERS.INDEX,
          },
        ],
        sellersContentMenu: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.INDEX },
        ],
        sellersSEOFAQsList: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.LIST },
          { text: 'SEO-FAQ', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.LIST },
        ],
        sellersSEOFAQsCreate: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.LIST },
          { text: 'SEO-FAQ', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.LIST },
          {
            text: 'Создание SEO-FAQ',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.CREATE,
          },
        ],
        sellersSEOFAQsEdit: [
          { text: 'AddSellers', to: ADDSELLERS_ADMIN_ROUTES.INDEX },
          { text: 'Контент и SEO', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.LIST },
          { text: 'SEO-FAQ', to: ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.LIST },
          {
            text: 'Редактирование SEO-FAQ',
            to: ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.EDIT,
          },
        ],
        default: [{ text: 'AddSellers', link: ADDSELLERS_ADMIN_ROUTES.INDEX }],
      },
    }
  },
  computed: {
    curBreadcrumb() {
      return this.breadcrumb[this.$route.name] || this.breadcrumb.default
    },
    isAuth() {
      return this.$store.getters.isAuth
    },
  },
  methods: {
    getRouterLink(to) {
      for (let key in this.$route.params) {
        to = to.replaceAll(`:${key}`, this.$route.params[key])
      }
      return to
    },
  },
}
</script>

<style lang="scss" module>
.header {
  display: flex;
  justify-content: space-between;
  background: $white;
  padding: 0.75rem 5rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      list-style: none;
      &:not(:first-child) {
        margin-left: 0.688rem;
        &::before {
          content: '/';
          color: $dark-gray;
        }
      }
      > a {
        transition: all 0.5s ease;
        &:hover {
          color: $black-gray;
        }
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span,
    a {
      font-size: 1.2rem;
      font-weight: bold;
      color: $dark-gray;
    }

    a {
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover,
      &.active {
        color: $black-gray;
      }
    }
  }
  svg {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }
}
</style>
